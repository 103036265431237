import React, {useEffect, useState} from 'react'
import styled from 'styled-components';
import Container from '../layout/Container';
import Section from '../layout/Section';
import {Exclamation} from '@styled-icons/fa-solid/Exclamation';
import {H1, SectionTitle, SectionHeader, P, P3, Img, Button} from '../elements';
import {
  layout,
  colors,
  breakpoints,
} from '../../styles/_variables';
import {
  responsive_font,
  font_family,
} from '../../styles/_mixins';
import {DonationTypes} from '../donate/enums'
const profileImg = require('../../assets/svg/profile1.svg');


const Content = styled.div.attrs({
  className: `flex flex-col md:flex-row
  mt-16`
})`
`;
const Payment = styled.div.attrs({
  className: `flex items-center py-4 px-6 rounded-lg md:w-1/3`
})`
  background-color: ${colors.bg_white};
  * {
    margin: 0;
  }
`;
const ProfileImg = styled.div.attrs({
  className: `w-2/5 pr-6`
})`
  max-width: 120px;
`;
const Period = styled.p.attrs({
  className: `uppercase pt-4`
})`
  ${responsive_font('small')};
  font-weight: 700;
`;
const Note = styled.div.attrs({
  className: `flex items-center p-4 pl-2 rounded-lg md:w-2/3 
    mt-6 md:ml-6 md:mt-0`
})`
  background-color: ${colors.bg_white};
  background-image: url(${Exclamation});
`;

export default function Commitment({data}) {
  const [amount, setAmount] = useState(null);
  const [duration, setDuration] = useState(null);
  useEffect(() => {
    const amountValue = new URL(window.location.href).searchParams.get('amount');
    const durationValue = new URL(window.location.href).searchParams.get('duration');
    setAmount(amountValue);
    setDuration(durationValue);
  }, [])

  return (
    <>
    <Section bg={colors.bg_secondary}>
      <Container>
        <SectionTitle>{data.title}</SectionTitle>
        <Content>
          {amount && (
            <Payment>
              <ProfileImg>
                <img src={profileImg} />
              </ProfileImg>
              <div className="flex-col">
                <p>
                  <SectionHeader className="inline-block">
                    {`₹${amount}`}
                  </SectionHeader>
                  {duration !== DonationTypes.Once ? "/ month" : ""}
                </p>
                {duration !== DonationTypes.Once 
                  ? <Period>{`${duration} months`}</Period>
                  : null}
              </div>
            </Payment>
          )}
          <Note>
            <span>
              <Exclamation size="5em" color={colors.bg_primary} />
            </span>
            <div className="flex-col">
              <P noMargin color={colors.primary} fw="bold">{data.note.title}</P>
              <P3>{data.note.description}</P3>
            </div>
          </Note>
        </Content>
      </Container>
    </Section>
    </>
  )
}
