import React, {useEffect, useState} from 'react'
import styled, {keyframes} from 'styled-components';
import Container from '../layout/Container';
import Section from '../layout/Section';
import {H1, SectionHeader, P, Img} from '../elements';
import {
  layout,
  colors,
  breakpoints,
} from '../../styles/_variables';
import {
  responsive_font,
  font_family,
} from '../../styles/_mixins';
// import Contact from './Contact';
const BGImg = styled(Section).attrs({
  className: `w-full h-full absolute`
})`
  color: ${colors.text_white};
  /* z-index: -1; */
  background-image: url(${p => p.image.childImageSharp.fluid.src});
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
`;
const Content = styled.div.attrs({
  className: `lg:w-1/2 py-6`
})`
`;

export default function Donate(props) {
  const {data} = props;
  return (
    <>
    {/* <BGImg image={data.bgImage} /> */}
    <BGImg image={data.bgImage}>
      <Container>
        <Content>
          <H1 white>{data.title}</H1>
          <P>{data.description}</P>
        </Content>
      </Container>
    </BGImg>
    </>
  )
}
