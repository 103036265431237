import React, {useState} from 'react'
import styled from 'styled-components';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Container from '../layout/Container';
import Section from '../layout/Section';
import {Exclamation} from '@styled-icons/fa-solid/Exclamation';
import {Facebook} from '@styled-icons/boxicons-logos/Facebook';
import {Twitter} from '@styled-icons/boxicons-logos/Twitter';
import {Whatsapp} from '@styled-icons/fa-brands/Whatsapp';
import {H1, SectionTitle, SectionHeader, P, P3, Img, Button} from '../elements';
import {
  layout,
  colors,
  breakpoints,
} from '../../styles/_variables';
import {
  responsive_font,
  font_family,
} from '../../styles/_mixins';
const profileImg = require('../../assets/svg/profile1.svg');


const Content = styled.div.attrs({
  className: `flex flex-col lg:flex-row
  mt-16`
})`
`;
const Desc = styled(P3).attrs({
  className: `lg:w-1/2 lg:mr-20 lg:mr-40`
})`
`;
const CopyButton = styled(Button).attrs({
  className: ``
})`
  @media screen and (max-width: 360px) {
    font-size: 0.8em;
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    flex-grow: 1;
    min-width: 120px;
  }
`;
const Share = styled.div.attrs({
  className: `lg:w-1/2 flex flex-col mt-4 lg:mt-0`
})`
  @media screen and (min-width: ${breakpoints.lg}) {
    transform: translateY(-1em);
  }
`;
const Link = styled.div.attrs({
  className: `flex justify-between items-center rounded-lg h-10 mt-6`
})`
  overflow: hidden;
  & input {
    color: ${colors.text_gray};
    background-color: ${colors.bg_white};
    padding: 0.5em 1em;
    align-self: center;
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
  }
  & input {
    min-width: 100px;
  }
`;
const SocialIcons = styled.div.attrs({
  className: `flex items-start`
})`
`;
const SocialIcon = styled.a.attrs({
  className: `w-12 h-12 p-3 rounded-full mr-6`,
  target: "_blank",
  noopener: true,
})`
  flex-shrink: 0;
  background-color: ${colors.bg_white};
  &.whatsapp--mobile {
    @media screen and (min-width: ${breakpoints.md}) {
      display: none;
    }
  }
  &.whatsapp {
    @media screen and (max-width: ${breakpoints.md}) {
      display: none;
    }
  }
`;


export default function Commitment(props) {
  const {data} = props;
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const handleClipboardCopy = () => {
    console.log('dfdf');
    setIsLinkCopied(true);
    setTimeout(() => {
      setIsLinkCopied(false);
    }, 1000);
  }
  return (
    <>
    <Section>
      <Container>
        <SectionTitle>{data.title}</SectionTitle>
        <Content>
          <Desc>{data.description}</Desc>
          <Share>
            <SocialIcons>
              <SocialIcon href={`https://www.facebook.com/sharer/sharer.php?u=${data.shareLink}&t=${data.shareMessage}`}>
                <Facebook size="100%" color={colors.primary} />
              </SocialIcon>
              <SocialIcon 
                href={`https://twitter.com/intent/tweet?text=${data.shareMessage}%0A%0A${data.shareLink}`}
              >
                <Twitter size="100%" color={colors.primary} />
              </SocialIcon>
              <SocialIcon
                href={`whatsapp://send?text=${data.shareMessage}%0A%0A${data.shareLink}`}
                data-action="share/whatsapp/share"
                className="whatsapp--mobile"
              >
                <Whatsapp size="100%" color={colors.primary} />
              </SocialIcon>
              <SocialIcon
                href={`https://web.whatsapp.com/send?text=${data.shareMessage}%0A%0A${data.shareLink}`}
                data-action="share/whatsapp/share"
                className="whatsapp"
              >
                <Whatsapp size="100%" color={colors.primary} />
              </SocialIcon>
            </SocialIcons>
            
            <Link>
              <input classNam="rounded-lg" disabled type="text" value={data.shareLink} />
              <CopyToClipboard 
                text={data.shareLink}
                onCopy={handleClipboardCopy}
              >
                <CopyButton className="lg:w-48">{isLinkCopied? "COPIED" : "COPY LINK"}</CopyButton>
              </CopyToClipboard>
            </Link>
          </Share>
        </Content>
      </Container>
    </Section>
    </>
  )
}
