import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import tw from "twin.macro";
import styled from "styled-components";
import Layout from "../components/PageLayout";
import Image from "../components/image";
import SEO from "../components/seo";
import Hero from '../components/thank-you/Hero';
import Commitment from '../components/thank-you/Commitment';
import Help from '../components/thank-you/Help';
import {HeaderOffset} from '../components/elements';


const H1 = styled.h1.attrs({
  className: `bg-gray-200 `,
})`
  ${tw` py-4 flex`};
`;

const IndexPage = () => {
  const query = useStaticQuery(graphql`
    query thankYouQuery {
      markdownRemark(frontmatter: {content: {eq: "thank-you"}}) {
        frontmatter {
          hero {
            title
            description
            bgImage {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          commitment {
            title
            note {
              title
              description
            }
          }
          help {
            title
            shareLink
            shareMessage
            description
          }
        }
      }
    }
  `);
  const data = query.markdownRemark.frontmatter;
  return (
    <Layout>
      <SEO title="Thank you" />
      <HeaderOffset />
      <Hero data={data.hero} />
      <Commitment data={data.commitment} />
      <Help data={data.help} />
    </Layout>
  )
};

export default IndexPage;
